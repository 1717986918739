import React from 'react';
import 'react-multi-carousel/lib/styles.css';

import challengeOne from '../../assets/images/sesame/challenge-1.png';
import challengeTwo from '../../assets/images/sesame/challenge-2.png';
import sesameOne from '../../assets/images/sesame/sesame-one.png';
import sesameTwo from '../../assets/images/sesame/sesame-two.png';
import sesameThree from '../../assets/images/sesame/sesame-three.png';
import syncupImg from '../../assets/images/sesame/syncup.png';
import sesameBanner from '../../assets/images/sesame/sesame-banner.png';
import bookmark from '../../assets/images/sesame/sesame-bookmark.png';
import tinLogo from '../../assets/images/gamerize/tinLogo.png';

class Sesame extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <section>
          <img className=" sesame-banner" src={sesameBanner} alt="banner" />
        </section>
        <section className="container sesame-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="mini-heading">Case Study - App Development</div>
              <div className="heading-ses">Sesame</div>
              <div className="heading-two">Workshop</div>
              <div className="row para-mini">
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeOne} alt="" />
                  <div className="subheading">The Challenge</div>
                  <p className="desc">
                    Sesame had a set of popular digital books that were live on the appstores but
                    had not been updated for some period
                  </p>
                </div>
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeTwo} alt="" />
                  <div className="subheading">The Outcome</div>
                  <p className="desc">
                    Tintash reviewed the legacy technology and updated it for best performance and
                    best practices on the latest devices
                  </p>
                </div>
              </div>
              <div className="subheading-big">The Project</div>
              <p className="description">
                Sesame Workshop engaged Tintash across 2020 for a vital project. The Monster at the
                End of this Book and Another Monster at the End of this Book are some of the most
                loved titles published by Sesame, whether in physical or digital form. But they were
                live on the appstores in legacy technologies since 2018 and could use a refresh with
                a view to their longevity.
              </p>
              <br />
              <p className="description">
                Through an RFP process, Tintash diligently evaluated the legacy code and art assets
                and concluded that it was best to port the apps from the existing cocos 2dx
                framework to Unity. This would allow slicker performance across the latest iOS and
                Android phones and tablets while also allowing the incorporation of key contemporary
                features such as analytics support and integration of relevant third-party services.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="mw-100" src={sesameOne} alt="sesame" />
            <div className="img-title">
              Another Monster at the End of this Book - The Main Menu Screen
            </div>
          </div>
        </section>
        <section className="container sesame-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="subheading-big">The Process</div>
              <p className="description">
                The project plan was developed following careful review of the existing art assets
                and animations. The art assets had to be re-used with the highest fidelity to stay
                true to the Sesame brand and the animations had to be timed to perfection through
                careful engineering and testing since all the interactive elements in the digital
                books followed strict pedagogical guidelines that could never be compromised.
              </p>
              <br />
              <p className="description">
                Through the project, Tintash interacted closely with a team of three professionals
                from Sesame Workshop overseeing the project and providing guidance. Weekly calls
                allowed in-depth discussions and alignment opportunities as well as vigorous debate
                over priorities. The shared project management tools such as the Jira board ensured
                all discussions were documented and could be referenced as needed.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="mw-100" src={sesameTwo} alt="sesame" />
            <div className="img-title">
              Another Monster at the End of this Book - The In-Game Screen
            </div>
          </div>
        </section>
        <section className="container sesame-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                Tintash identified early on that a fair amount of the architecture could be re-used
                between the two books and planned accordingly. Each page of the books was
                meticulously developed using the Spine tool taking into account that the complexity
                of art and animations could vary tremendously across pages. A balance was struck
                between automating the animations where practical, and manual development where it
                was needed, to stay true to the spirit of the original product.
              </p>
              <br />
              <p className="description">
                Tintash also brought these properties to Amazon Fire TV which was among the first
                such efforts for Sesame. This involved setting up carefully choreographed
                interactions across this hardware and was a key component of the launch plan.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="mw-100" src={sesameThree} alt="" />
            <div className="img-title">
              Another Monster at the End of this Book - The In-Game Screen
            </div>
          </div>
        </section>
        <section className="container sesame-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                We worked on a tight timeline to have things ready in time for Sesame’s marketing
                efforts in November for their launch on HBO. We agreed on a set of pre-launch and
                post-launch priorities to have something live for this big event and then wrap up
                some items at a more leisurely pace later. The final products live on the appstores
                are tightly integrated with Sesame APIs including their cross-promotional efforts
                and are thus robust products that can serve for the near future.
              </p>
              <br />
              <p className="description">
                Tintash is proud to become part of the Sesame family and support the mission of
                making children ‘smarter, stronger and kinder’. It has been a privilege to work
                together to ensure that the goal of educating children was not compromised in a year
                of covid uncertainty
              </p>
            </div>
          </div>
        </section>
        <section className="container team-syncup">
          <div className="profile-mini-heading">Behind the scene</div>
          <div className="profile-heading-a">
            Team <span className="profile-heading-b">Sync Up</span>
          </div>
          <div className="picture-banner">
            <img className="mw-100" src={syncupImg} alt="" />
          </div>
        </section>

        <section className="container last-section">
          <div className="row">
            <div className="col-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{marginTop: '-16px'}}
              >
                <img src={bookmark} alt="bm" />
              </div>
              {/* <BookmarkIcon fontSize="large" style={bookmark} /> */}
              <div className="last-section-content">
                <p className="desc-text">
                  <span className="bold">
                    Sesame Workshop engaged Tintash across 2020 for a vital project.
                  </span>
                  &nbsp;The Monster at the End of this Book and Another Monster at the End of this
                  Book are some of the most loved titles published by Sesame, whether in physical or
                  digital form. But they were live on the appstores in legacy technologies since
                  2018 and could use a refresh with a view to their longevity.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container tintash-sign">
          <div className="row">
            <div className="col-12">
              <div className="tin-text"> Created and Developed by</div>
              <div className="tin-img">
                {' '}
                <img src={tinLogo} alt="bm" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Sesame;
